import Link from "@Components/common/Link";
import { mq, mw, rgba } from "@Utils/StyleUtil";
import { LogoWrapper } from "public/svgs/logos/KsaNationalDayLogo";
import styled, { css } from "styled-components";

export const FooterWrapper = styled.footer<{ readonly baseFooter?: boolean }>`
  min-height: 100vh;
  @media (min-height: 1441px) {
    min-height: auto;
    padding: 100px 0px 0px;
  }
  margin-top: -40px;
  background-color: ${({ theme }) => theme.colors.darkSeagule};
  color: ${({ theme }) => theme.colors.white};
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  ${({ baseFooter }) =>
    baseFooter &&
    css`
      position: relative;
      min-height: unset;
      margin-top: 0;
    `}
  ${LogoWrapper} {
    ${mq(1024)} {
      display: block;
    }
  }
`;

export const FooterInner = styled.div`
  padding-top: 40px;
`;
export const ListsWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
export const List = styled.div`
  display: flex;
  flex-direction: column;
  ${mq(992)} {
    &:not(.mobile-visible) {
      display: none;
    }
  }
`;
export const BaimsSlug = styled.p`
  font-size: 2rem;
  line-height: 1.6;
  min-width: 173px;
  max-width: 173px;
  margin: 24px 0;
  ${mq(992)} {
    display: none;
  }
`;
export const StoreLink = styled.a`
  display: block;
  min-width: 145.06px;
  max-width: 145.06px;
  height: 44px;
  margin-bottom: 8px;
  text-align: center;
  &:last-of-type {
    margin-bottom: 0;
  }
  img {
    display: block;
    object-fit: contain;
    width: 100%;
    height: 100%;
  }
  ${mq(992)} {
    display: none;
  }
`;
export const ListTitle = styled.h3`
  font-size: 1.8rem;
  line-height: 1.5;
  font-weight: 600;
  margin-bottom: 20px;
  ${mw(1920)} {
    font-size: calc(1.8rem * 1.2);
  }
`;
export const ListItem = styled.div`
  margin-bottom: 16px;
`;
export const ListLink = styled(Link)`
  display: inline-block;
  font-size: 1.6rem;
  line-height: 1.5;
  color: ${({ theme }) => theme.colors.white};
  transition: 0.3s ease transform;
  ${mw(1920)} {
    font-size: calc(1.6rem * 1.2);
  }
  &:hover {
    transform: translateX(5px);
  }
`;
export const SocialIconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 20px;
  min-width: 127px;
`;
export const PaymentMethodsWrapper = styled.div<{ readonly forMobile?: boolean }>`
  display: flex;
  flex-wrap: wrap;
  gap: 6.72px;
  margin-top: 30px;
  ${mq(992)} {
    display: none;
  }
  ${({ forMobile }) =>
    forMobile &&
    css`
      display: none;
      justify-content: center;
      gap: 24px;
      margin-bottom: -8px;
      ${mq(992)} {
        display: flex;
      }
    `}
`;
export const SocialLink = styled.a`
  img {
    display: block;
    object-fit: contain;
  }
`;
export const Copyrights = styled.div`
  border-top: 1px solid ${({ theme }) => rgba(theme.colors.gray3, 0.2)};
  margin-top: 24px;
  padding: 16px 0;
  font-size: 1.4rem;
  line-height: 1.7;
  display: flex;
  align-items: center;
  justify-content: space-between;
  ${mq(576)} {
    flex-direction: column-reverse;
  }
  ${mw(1920)} {
    font-size: calc(1.4rem * 1.2);
  }
`;
export const SupportedPayments = styled.div`
  display: flex;
  align-items: center;
  gap: 12px;
  #zain-logo path {
    fill: #ADAFB2;
  }
  ${mq(576)} {
    margin: 16px 0;
  }
`;
export const MobileCollapses = styled.div`
  display: none;
  flex-direction: column;
  padding-top: 8px;
  ${mq(992)} {
    display: flex;
  }
`;
export const CollapseHeader = styled.div`
  font-size: 1.6rem;
  line-height: 1.5;
  font-weight: 400;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 8px;
`;
export const CollapseWrapper = styled.div`
  .ReactCollapse--collapse {
    transition: height 400ms;
  }
  border-bottom: 1px solid ${({ theme }) => rgba(theme.colors.gray3, 0.2)};
  padding: 16px 0 8px;
  &:last-of-type {
    border-bottom: none;
  }
  ${ListItem} {
    margin-bottom: 0;
    padding-left: 16px;
  }
  ${ListLink}, ${SocialIconsWrapper} {
    padding: 8px 0;
  }
`;
export const CollapseArrow = styled.div<{ readonly isOpen?: boolean }>`
  min-width: 20px;
  max-width: 20px;
  min-height: 20px;
  max-height: 20px;
  transition: 0.3s ease transform;
  img {
    display: block;
    object-fit: contain;
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      transform: rotate(180deg);
    `}
`;

export const TamaraLogoContainer = styled.div<{ readonly isAr?: boolean }>`
  & > svg {
    height: 25px;
    margin-top: 10px;
    ${({ isAr }) =>
      isAr
        ? css`
            width: 68px;
          `
        : css`
            width: 100px;
          `}
  }
`;
