import { CommonButton } from "@Components/common/CommonButton";
import Link from "@Components/common/Link";
import { FOUNDING_DAY_THEME, KSA_COUNTRY_CODE } from "@Constants/index";
import { rotateSpinner } from "@Style/animations";
import { mq, rgba, stripButton } from "@Utils/StyleUtil";
import { getHomeBgColor, getSearchBarColor } from "LocaleBased";
import styled, { css } from "styled-components";
import { NavbarVariant } from "types/common";

export const BlurredBg = styled.div<{
  readonly isActive: boolean;
  readonly isZainPopup: boolean;
}>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.15);
  backdrop-filter: blur(6px);
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition:
    0.3s ease opacity,
    0.3s ease visibility;
  transition-delay: 0.1s, 0.1s;
  z-index: ${({ isZainPopup }) => (isZainPopup ? 300 : 99)} !important;
  ${({ isActive }) =>
    isActive &&
    css`
      transition-delay: 0s, 0s;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`;
export const HeaderInner = styled.div`
  display: flex;
  align-items: center;
`;
export const LogoLink = styled(Link)`
  display: block;
  margin-inline-end: 30px;
  display: flex;
  cursor: pointer;
`;
export const BasicStyledLogo = styled.img`
  width: 118px;
  height: 52px;
  object-fit: contain;
  ${mq(1024)} {
    width: 100px;
    height: 44px;
  }
`;
export const Nav = styled.nav`
  ${mq(1024)} {
    display: none;
  }
`;

export const NavUl = styled.ul`
  display: flex;
  align-items: center;
  gap: 30px;
  @media (max-width: 767.98px) {
    display: none;
  }
`;
export const NavLi = styled.li`
  position: relative;
`;

export const ExploreMenuWrapper = styled.div<{ readonly show?: boolean }>`
  position: absolute;
  display: flex;
  left: 0;
  top: 70px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition:
    0.3s ease opacity,
    0.3s ease visibility;
  transition-delay: 0.1s, 0.1s;
  width: auto;
  ${({ show }) =>
    show &&
    css`
      transition-delay: 0s, 0s;
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
    `}
`;

export const NavLink = styled(Link)`
  font-size: 1.6rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  transition: 0.3s ease all;
  div& {
    cursor: pointer;
  }
`;

export const ExploreTrigger = styled(Link)`
  font-size: 1.6rem;
  white-space: nowrap;
  color: ${({ theme }) => theme.colors.white};
  text-transform: capitalize;
  transition: 0.3s ease all;
  cursor: pointer;
`;

export const LanguageWrapper = styled.div`
  position: relative;
  ${mq(576)} {
    display: none;
  }
`;
export const GlobeIcon = styled.img`
  display: block;
  width: 18.59px;
  height: 18.59px;
`;
export const ArrowIcon = styled.img`
  display: block;
  width: 12px;
  height: 6px;
  transition: 0.3s ease transform;
  ${mq(1024)} {
    display: none;
  }
`;
export const SelectedLanguage = styled.div`
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.white};
  margin: 0 10px 0 8.7px;
  transition: 0.3s ease all;
  ${mq(1024)} {
    display: none;
  }
`;
export const DropdownWrapper = styled.button<{
  readonly isOpen?: boolean;
}>`
  ${stripButton};
  display: flex;
  align-items: center;
  line-height: 1.5;
  padding: 2px 0;
  ${({ isOpen }) =>
    isOpen &&
    css`
      ${ArrowIcon} {
        transform: rotate(-180deg);
      }
    `}
`;
export const NavbarDropdown = styled.ul<{
  readonly isOpen: boolean;
  position?: "right" | "left";
  isRecursive?: boolean;
}>`
  position: absolute;
  top: 18%;
  ${mq(576)} {
    top: 100%;
  }
  ${({ position }) =>
    position
      ? position === "left"
        ? css`
            right: 100%;
          `
        : css`
            right: 0;
          `
      : css`
          left: 50%;
        `}
  width: 100%;
  min-width: 132px;
  /* padding: 8px 0; */
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: ${({ position }) =>
    position ? (position === "left" ? "translate(100%, 10px)" : "translate(0, 10px)") : "translate(-50%, -10px)"};
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 8px;
  z-index: 100;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  display: flex;
  flex-direction: column;
  &:lang(ar) {
    direction: rtl;
  }
  ${({ isOpen, position }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: ${position
        ? position === "left"
          ? "translate(100%, 26px)"
          : "translate(0, 51px)"
        : "translate(-50%, 46px)"};
    `}
`;
export const DropdownLang = styled(Link)<{ readonly isSelected: boolean }>`
  text-align: left;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  display: flex;
  align-items: center;
  padding: 16px 12px;
  position: relative;
  background-color: ${({ theme }) => theme.colors.white};
  color: ${({ theme }) => theme.colors.nero};
  transition: 0.2s ease background-color;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
  }
  &.ar {
    font-family: ${({ theme }) => theme.typography.fontFamilyAr};
  }
  /* &::before {
    content: "";
    display: block;
    min-width: 16px;
    max-width: 16px;
    min-height: 16px;
    max-height: 16px;
    border-radius: 50%;
    border: 1px solid ${({ theme }) => theme.colors.gray8};
    margin-right: 14px;
  } */
  /* &::after {
    content: "";
    position: absolute;
    left: 10px;
    top: 50%;
    transform: translateY(-50%);
    background-color: ${({ theme }) => theme.colors.seagule};
    min-width: 12px;
    max-width: 12px;
    min-height: 12px;
    max-height: 12px;
    border-radius: 50%;
    opacity: 0;
  } */
  &::after {
    content: "";
    padding: 0;
    margin: 0;
    position: absolute;
    left: 0;
    bottom: 0;
    background-color: #efefef;
    width: 100%;
    height: 1px;
  }
  ${({ isSelected }) =>
    isSelected &&
    css`
      color: ${({ theme }) => theme.colors.seagule};
    `}
`;
export const Separator = styled.div<{ readonly isMobile?: boolean }>`
  width: 1px;
  opacity: 0.4;
  background: ${({ theme }) => theme.colors.gray5};
  height: 32px;
  margin: 0 25px 0 30px;
  ${mq(1024)} {
    margin: 0 20px 0 17px;
  }
  ${mq(576)} {
    display: ${({ isMobile }) => (isMobile ? "unset" : "none")};
  }
`;
export const LoginButton = styled(CommonButton)`
  min-width: 116px;
  height: 48px;
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  &:lang(ar) {
    padding: 0px;
  }
`;
export const SearchBoxWrapper = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  .search-box {
    height: 100%;
    form {
      height: 100%;
    }
    input {
      width: 100%;
      height: 100%;
      background-color: transparent;
      border: 0;
      padding: 0 44px;
      appearance: none;
      color: ${({ theme }) => theme.colors.white};
      transition: 0.3s ease all;
      &::-webkit-search-cancel-button {
        display: none;
        -webkit-appearance: none;
      }
      &::placeholder {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
        transition: 0.3s ease all;
        color: ${({ theme }) => rgba(theme.colors.white, 0.7)};
      }
    }
    button {
      position: absolute;
      transition: 0.3s ease all;
      top: 50%;
      transform: translateY(-50%);
      ${stripButton};
      &[type="submit"] {
        left: 16px;
        line-height: 1;
        cursor: pointer;
        ${stripButton};
      }
      &[type="reset"] {
        line-height: 0;
        right: 16px;
        path {
          fill: ${({ theme }) => theme.colors.white};
        }
      }
      & + span {
        /* Loading Spinner */
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        right: 16px;
        line-height: 1;
      }
    }
  }
`;
export const NavbarSearchWrapper = styled.div`
  height: 48px;
  background: ${({ theme }) => rgba(theme.colors.white, 0.2)};
  backdrop-filter: blur(20px);
  width: 100%;
  display: flex;
  align-items: center;
  margin: 0 40px 0 36px;
  border-radius: 8px;
  position: relative;
  transition: 0.3s ease all;
  ${mq(768)} {
    margin-left: 0;
  }
  ${mq(576)} {
    display: none;
  }
`;

export const SearchResultsDropdown = styled.div<{ readonly isOpen: boolean }>`
  position: absolute;
  top: 70%;
  left: 50%;
  width: 100%;
  min-width: 420px;
  padding: 0 16px;
  opacity: 0;
  visibility: hidden;
  pointer-events: none;
  transition: 0.4s ease all;
  transform: translate(-50%, 10px);
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.08);
  border-radius: 12px;
  z-index: 100;
  background: ${({ theme }) => theme.colors.white};
  overflow: hidden;
  ol {
    list-style: none;
    padding: 0;
  }
  li {
    margin-bottom: 12px;
    &:last-of-type {
      margin-bottom: 0;
    }
  }
  ${({ isOpen }) =>
    isOpen &&
    css`
      opacity: 1;
      visibility: visible;
      pointer-events: auto;
      transform: translate(-50%, 26px);
    `}
`;
export const SimpleHitWrapper = styled.a`
  font-weight: 500;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.gray1};
  display: flex;
  align-items: center;
  gap: 12px;
  padding: 8px;
  border-radius: 8px;
  transition: 0.3s ease all;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
  }
`;
export const SimpleHitIcon = styled.img`
  display: block;
  margin-right: 12px;
`;
export const SearchResultsTitle = styled.h3<{ readonly align?: "center" }>`
  font-size: 16px;
  font-weight: 700;
  line-height: 28px;
  margin-bottom: 12px;
  ${({ align }) =>
    align === "center" &&
    css`
      text-align: center;
    `}
`;
export const EmptySearchDesc = styled.p`
  color: #8e8e8e;
  font-size: 16px;
  font-weight: 400;
  line-height: 28px;
  margin-bottom: 16px;
  text-align: center;
`;
export const SupportIcon = styled.img`
  display: block;
  margin-right: 12px;
`;
export const SearchCardWrapper = styled(Link)`
  display: flex;
  border-radius: 8px;
  overflow: hidden;
  padding: 8px;
  margin-bottom: 4px;
  transition: 0.2s ease background-color;
  cursor: pointer;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
  }
  &:last-of-type {
    margin-bottom: 8px;
  }
`;
export const SearchCardImg = styled.img<{ readonly isCircle?: boolean; isPackage?: boolean }>`
  display: block;
  min-width: ${({ isCircle }) => (isCircle ? "52px" : "100px")};
  max-width: ${({ isCircle }) => (isCircle ? "52px" : "100px")};
  border-radius: ${({ isCircle }) => (isCircle ? "50%" : "8px")};
  height: ${({ isCircle }) => (isCircle ? "52px" : "76px")};
  overflow: hidden;
  margin-right: 12px;
  object-fit: ${({ isCircle }) => (isCircle ? "cover" : "scale-down")};
  background: ${({ theme, isPackage }) => (isPackage ? theme.colors.gray6 : theme.colors.gray4)};
`;
export const SearchCardInfo = styled.div`
  flex: 1;
`;
export const SearchCardTitle = styled.h4`
  font-size: 16px;
  font-weight: 600;
  line-height: 28px;
  color: ${({ theme }) => theme.colors.nero};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const SearchCardSubTitle = styled.div`
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  color: ${({ theme }) => theme.colors.gray2};
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  display: flex;
  align-items: center;
  gap: 8px;
`;
export const Point = styled.div`
  max-width: 5px;
  min-width: 5px;
  max-height: 5px;
  min-height: 5px;
  background-color: ${({ theme }) => theme.colors.gray2};
  border-radius: 50%;
`;
export const SpinnerWrapper = styled.div`
  min-width: 116px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
export const Spinner = styled.div<{
  readonly size?: number;
}>`
  min-height: ${({ size }) => (size ? size : "30")}px;
  min-width: ${({ size }) => (size ? size : "30")}px;
  max-height: ${({ size }) => (size ? size : "30")}px;
  max-width: ${({ size }) => (size ? size : "30")}px;
  color: #333;
  display: inline-block;
  border: 2px solid #ddd;
  border-radius: 50%;
  border-right-color: #333;
  animation: ${rotateSpinner} 1s linear infinite;
`;
export const ProfileBtn = styled.button`
  ${stripButton};
  display: flex;
  min-width: 48px;
  max-width: 48px;
  min-height: 48px;
  max-height: 48px;
  border-radius: 50%;
  overflow: hidden;
  background-color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  align-items: center;
  justify-content: center;
  line-height: 1;
  &.gray {
    background-color: ${({ theme }) => theme.colors.gray5};
  }
  img {
    display: block;
    object-fit: cover;
    width: 100%;
    height: 100%;
    font-size: 1rem;
  }
`;
export const ProfileDropdownLink = styled(Link)`
  button& {
    ${stripButton};
    width: 100%;
    color: ${({ theme }) => theme.colors.red} !important;
  }
  && {
    font-size: 16px;
    font-weight: 500;
    line-height: 28px;
    text-align: left;
    display: flex;
    align-items: center;
    line-height: 1.5;
    padding: 8px;
    position: relative;
    background-color: ${({ theme }) => theme.colors.white};
    color: ${({ theme }) => theme.colors.nero};
    transition: 0.2s ease background-color;
    &:hover {
      background-color: ${({ theme }) => theme.colors.gray6};
    }
  }
`;
const Burger = ({ className, onClick }) => (
  <svg className={className} onClick={onClick} viewBox="270 220 400 200">
    <path d="M300,220 C300,220 520,220 540,220 C740,220 640,540 520,420 C440,340 300,200 300,200" id="top"></path>
    <path d="M300,320 L540,320" id="middle"></path>
    <path
      d="M300,210 C300,210 520,210 540,210 C740,210 640,530 520,410 C440,330 300,190 300,190"
      id="bottom"
      transform="translate(480, 320) scale(1, -1) translate(-480, -318) "
    ></path>
  </svg>
);
export const BurgerMenu = styled(Burger)<{
  readonly newTheme?: boolean;
  readonly isOpened?: boolean;
}>`
  margin-left: auto;
  z-index: 101;
  min-width: 36px;
  max-width: 36px;
  height: 36px;
  ${({ isOpened }) =>
    isOpened &&
    css`
      position: absolute;
      top: -90px;
      left: 5px;
    `}
  cursor: pointer;
  user-select: none;
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  path {
    fill: none;
    -webkit-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -moz-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -o-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    -ms-transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    transition:
      stroke-dashoffset 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke-dasharray 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25),
      stroke 0.5s cubic-bezier(0.25, -0.25, 0.75, 1.25);
    stroke-width: 30px;
    stroke-linecap: round;
    stroke: ${({ theme }) => theme.colors.white};
    stroke-dashoffset: 0px;
    transition: 0.3s ease all;
  }
  path#top,
  path#bottom {
    stroke-dasharray: 240px 950px;
  }
  path#middle {
    stroke-dasharray: 240px 240px;
  }
  &.cross path#top,
  &.cross path#bottom {
    stroke-dashoffset: -650px;
    stroke-dashoffset: -650px;
  }
  &.cross path#middle {
    stroke-dashoffset: -115px;
    stroke-dasharray: 1px 220px;
  }
  &.cross path {
    stroke: ${({ theme }) => theme.colors.white};
    ${mq(576)} {
      stroke: ${({ theme }) => theme.colors.nero} !important;
    }
  }
  z-index: 101;
  position: relative;
`;

export const Header = styled.header<{
  readonly variant: NavbarVariant;
  readonly navBorder?: boolean;
  readonly isResMenuOpen?: boolean;
  readonly isBannerVisible?: boolean;
  readonly countryCode?: string;
  readonly themeType: string;
  readonly isLandingPage: string;
  readonly isOrcasBanner?: boolean;
}>`
  position: ${({ variant }) => (variant === "basic" ? "static" : "sticky")};
  &.is-landing-nav {
    position: ${({ variant }) => (variant === "basic" ? "static" : "fixed")} !important;
  }
  top: 0;
  left: 0;
  width: 100%;
  padding: ${({ isBannerVisible }) => (isBannerVisible ? "0 0 28px 0" : "28px 0")};
  line-height: 1.5;
  z-index: 100;
  transform: none;
  border-bottom: ${({ navBorder, theme }) => (navBorder ? `1px solid ${theme.colors.gray5_old}` : "")};
  ${mq(1024)} {
    padding: ${({ isBannerVisible, isOrcasBanner }) =>
      isBannerVisible ? "0 0 18px 0" : isOrcasBanner ? "28px 0" : "18px 0"};
  }
  ${mq(576)} {
    padding: ${({ isBannerVisible, isOrcasBanner }) =>
      isBannerVisible ? "0 14px 12px" : isOrcasBanner ? "28px 0" : "14px 12px"};
  }
  transition: 0.5s ease all;
  ${({ variant, isResMenuOpen, countryCode, themeType, isLandingPage }) =>
    variant === "white-bg" &&
    css`
      backdrop-filter: blur(20px);
      background-color: ${rgba(getHomeBgColor(countryCode as any, themeType), 0.8)} !important;
      transition-delay: 1s;
      ${isResMenuOpen
        ? css`
            transition-delay: 0.5s;
            transition: none;
          `
        : css`
            backdrop-filter: blur(20px);
            background-color: ${countryCode === KSA_COUNTRY_CODE && themeType === FOUNDING_DAY_THEME
              ? "rgba(255, 245, 218, 0.8)"
              : "rgba(255, 255, 255, 0.8)"};
            /* transition-delay: 1s; */
          `}
      ${NavLink}, ${SelectedLanguage} {
        color: ${({ theme }) => theme.colors.nero};
        /* transition-delay: 1s; */
      }
      ${NavbarSearchWrapper} {
        /* transition-delay: 1s; */
        background-color: ${getSearchBarColor(countryCode as any, themeType, isLandingPage)};
      }
      ${SearchBoxWrapper} {
        .search-box {
          input {
            color: ${({ theme }) => theme.colors.nero};
            /* transition-delay: 1s; */
            &::placeholder {
              color: ${({ theme }) => rgba(theme.colors.gray1, 0.7)};
              /* transition-delay: 1s; */
            }
          }
          button[type="reset"] path {
            fill: ${({ theme }) => theme.colors.nero};
            /* transition-delay: 1s; */
          }
        }
      }
      ${BurgerMenu} {
        path {
          stroke: ${({ theme }) => theme.colors.nero};
          /* transition-delay: 0.5s; */
        }
      }
    `}

  ${({ variant }) =>
    variant === "seagull-bg" &&
    css`
      background-color: transparent;
      backdrop-filter: blur(20px);
    `}
  ${({ variant }) =>
    variant === "dark-bg" &&
    css`
      background-color: ${({ theme }) => rgba(theme.colors.nero, 0.8)};
      backdrop-filter: blur(20px);
    `}

  ${({ variant }) =>
    variant === "hidden" &&
    css`
      opacity: 0;
      visibility: hidden;
      transform: translateY(-100%);
    `}
`;
export const ResMenuContainer = styled.span<{ readonly isOpened?: boolean }>`
  ${({ isOpened }) =>
    isOpened &&
    css`
      position: relative;
    `}
  ${mq(576)} {
    margin-left: auto;
  }
`;
export const ResMenuWrapper = styled.div<{ readonly isOpen?: boolean }>`
  position: fixed;
  top: 82px;
  right: 20px;
  bottom: 20px;
  width: 100%;
  max-width: 284px;
  background-color: ${({ theme }) => theme.colors.white};
  z-index: 100;
  border-radius: 8px;
  visibility: ${({ isOpen }) => (isOpen ? "visible" : "hidden")};
  opacity: ${({ isOpen }) => (isOpen ? "1" : "0")};
  transition:
    0.4s ease opacity,
    0.3s ease visibility,
    0.5s ease transform;
  padding: 16px 0;
  transform: ${({ isOpen }) => (isOpen ? "translateX(0)" : "translateX(100%)")} translateY(16px);
  overflow-y: auto;
  min-height: 88vh;
  ${mq(576)} {
    padding-top: 60px;
    min-height: 100vh;
    max-width: none;
    right: 0;
    transform: none;
    top: 0;
    bottom: 0;
  }
  ${LogoLink} {
    display: flex;
    margin-right: 0;
    margin-left: 24px;
    margin-top: -2px;
    margin-bottom: 26px;
  }
  ${NavbarSearchWrapper} {
    display: flex;
    z-index: 101;
    margin: 0 24px 20px;
    width: auto;
  }
  ${SearchBoxWrapper} {
    .search-box {
      input {
        color: ${({ theme }) => theme.colors.nero};
        &::placeholder {
          color: ${({ theme }) => rgba(theme.colors.gray1, 0.7)};
        }
      }
      button[type="reset"] path {
        fill: ${({ theme }) => theme.colors.nero};
      }
    }
  }

  ${LanguageWrapper} {
    display: flex;
    max-width: 130px;
    border: 1px solid ${({ theme }) => theme.colors.gray5};
    border-radius: 6px;
    padding: 10px 14px;
    margin: 16px 0 40px 24px;
    ${DropdownWrapper} {
      padding: 0;
      width: 100%;
    }
    ${SelectedLanguage} {
      color: ${({ theme }) => theme.colors.nero};
      display: flex;
      font-weight: 400;
      font-size: 14px;
      line-height: 24px;
      margin: 0 14px 0 11px;
    }
    ${GlobeIcon} {
      width: 18px;
      height: 18px;
    }
    ${ArrowIcon} {
      display: flex;
    }
  }
  ${NavbarDropdown} {
    ${({ isOpen, position }: any) =>
      isOpen &&
      css`
        transform: ${position
          ? position === "left"
            ? "translate(100%, 12px)"
            : "translate(0, 12px)"
          : "translate(-50%, 12px)"};
      `}
  }
  ${SearchResultsDropdown} {
    min-width: calc(100vw - 48px);
    ${({ isOpen }) =>
      isOpen &&
      css`
        transform: translate(-50%, 12px);
      `}
  }
`;

export const ResMenuTitle = styled.h3`
  font-size: 18px;
  font-weight: 600;
  line-height: 32px;
  color: ${({ theme }) => theme.colors.gray2};
  margin: 0 16px 4px;
  ${mq(576)} {
    margin: 0 24px 4px;
  }
`;
export const ResMenuUl = styled.ul`
  margin-bottom: 28px;
`;
export const ResMenuLi = styled.li`
  position: relative;
  &:not(:last-of-type):not(.no-border)::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 16px;
    right: 16px;
    background-color: ${({ theme }) => theme.colors.gray8};
    height: 0.3px;
  }
`;
export const ResMenuLink = styled(Link)`
  ${stripButton};
  width: 100%;
  font-size: 18px;
  font-weight: 400;
  line-height: 32px;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: ${({ theme }) => theme.colors.nero};
  transition: 0.2s ease background-color;
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
  }
  ${mq(576)} {
    padding: 12px 24px;
  }
`;
export const ResMenuIcon = styled.img`
  transform: rotate(0deg) /*rtl: rotate(180deg) */;
`;
export const AppsWrapper = styled.div`
  margin-top: 10px;
`;
export const DownloadAppLink = styled.a`
  margin: 0 16px 12px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: ${({ theme }) => theme.colors.black};
  border-radius: 8px;
  &:lang(ar) {
    align-items: flex-end;
  }
  img {
    object-fit: contain;
    width: 170px;
  }
  ${mq(576)} {
    width: calc(45% - 30px);
    display: inline-flex;
    margin: 0;
    margin-left: 24px;
    margin-right: 12;
    &:last-of-type {
      margin-left: 12px;
      margin-right: 24px;
    }
    img {
      width: 100%;
    }
  }
  ${mq(414)} {
    width: calc(50% - 30px);
  }
`;
export const ResMenuProfile = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  max-width: 220px;
  span {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    font-weight: 400;
    margin-left: 16px;
  }
`;
export const BreadCrumbWrapper = styled.div`
  color: ${({ theme }) => theme.colors.gray1};
`;

export const ExploreDropdownWrapper = styled.div`
  position: relative;
`;

export const MainExploreDropdown = styled(NavbarDropdown)`
  min-width: 188px;
  padding: 8px;
  height: 300px;
  overflow-y: auto;
  ${({ isRecursive }) =>
    isRecursive &&
    css`
      position: static;
      transform: none;
    `}
`;
export const MainExploreMenuWrapper = styled.div<{ isOpen?: boolean }>`
  position: absolute;
  top: 100%;
  width: 100%;
  height: ${({ isOpen }) => (isOpen ? "calc(100% + 46px)" : "100%")};
  & > ${MainExploreDropdown} {
    top: 0;
  }
`;
export const RecursiveMenuWrapper = styled.div<{ depth?: number }>`
  padding-left: 4px;
  position: absolute;
  top: 100%;
  ${({ depth }) =>
    typeof depth !== "undefined" &&
    css`
      /* left: ${depth * 188 + 4 * (depth + 1)}px; */
      top: calc(100% + 26px);
      left: ${(depth + 1) * 188 + depth * 4}px;
    `}
`;
export const ExploreDropdownLi = styled.li`
  line-height: 1;
`;
export const ExploreDropdowLink = styled.span`
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  white-space: nowrap;
  font-size: 1.4rem;
  line-height: 1.71428571;
  padding: 8px 14px 8px 8px;
  transition: 0.3s ease background-color;
  border-radius: 8px;
  direction: ltr;
  color: ${({ theme }) => theme.colors.nero};
  &:hover {
    background-color: ${({ theme }) => theme.colors.gray6};
  }
  .emojie {
    font-size: 2.4rem;
    line-height: 24px;
  }
`;
export const ExploreDropdownContent = styled.div`
  display: flex;
  flex: 1;
  white-space: normal;
`;
export const ExploreDropdownArrow = styled.img`
  height: 11.430380821228027px;
  width: 6.4285712242126465px;
  display: block;
  object-fit: contain;
  transform: rotate(0) /*rtl: rotate(180deg) */;
`;
export const SeeAllResults = styled.h4`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  font-size: ${({ theme }) => theme.fontsizes.body16};
  line-height: 28px;
  color: ${({ theme }) => theme.colors.seagule};
  cursor: pointer;
`;
export const NavbarBannerWrapper = styled.div`
  background-color: ${({ theme }) => theme.colors.KsaFounding_primaryColor};
  color: ${({ theme }) => theme.colors.white};
  padding: 8px 24px;
  margin-bottom: 28px;
  line-height: 2;
  font-weight: ${({ theme }) => theme.fontWeight.font500};
  display: flex;
  justify-content: center;
  ${mq(768)} {
    padding: 16px 24px;
    text-align: center;
    flex-direction: column;
    align-items: center;
    line-height: 1.75;
    & > span {
      max-width: 255px;
      margin-bottom: 12px;
    }
  }
  ${mq(576)} {
    margin-left: -14px;
    margin-bottom: 14px;
    width: calc(100% + (14px * 2));
  }
`;
export const CounterWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-right: 16px /*rtl:ignore*/;
  font-weight: ${({ theme }) => theme.fontWeight.font600};
  span {
    margin: 0 4px;
  }
`;
export const CountBox = styled.div`
  background-color: ${({ theme }) => theme.colors.KsaFounding_naveColor};
  color: ${({ theme }) => theme.colors.KsaFounding_primaryColor};
  /* padding: 8px 6px; */
  border-radius: 5px;
  line-height: 1;
  min-width: 32px;
  max-width: 32px;
  min-height: 32px;
  max-height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
`;
